import {React, useEffect} from 'react'
import { FaGithub, FaTwitter,FaInstagram, FaEnvelope } from "react-icons/fa";
import Aos from "aos";
import "aos/dist/aos.css"
const Socials = () => {
  useEffect(() => {
    Aos.init({duration: 1000 });
  }, []);
  return (
    <>
    <section className='sec3'>
            <div data-aos="fade-up" className="footer-socials">
            <ul>
                <li data-aos="zoom-out-right"><a href="https://twitter.com/samayoo_"><FaTwitter className="fa"/></a></li>
                <li data-aos="fade-down"><a href="https://github.com/samayoo"><FaGithub className="fa"/></a></li>
                <li data-aos="fade-left"><a href="https://www.instagram.com/samayoo_/"><FaInstagram className="fa"/></a></li>
            </ul>
            </div>
    </section>
    </>
  )
}

export default Socials