import {React, useEffect} from "react"
import { FaCoffee } from "react-icons/fa";
//import { Link } from "react-router-dom";
import Socials from "../subcomponents/Socials";
import Aos from "aos";
import "aos/dist/aos.css"
//import Portfolio from "./Portfolio";

function Header() {
    useEffect(() => {
        Aos.init({duration: 1000 });
      }, []);
  return (
    <>
    <header data-aos="fade-right">
        <br/>
        <br/>
        <br/>
        <div className="sec1">
         <div className='wrapper'>
            <ul className='dynamic-txts'>
                <li><span >WELCOME</span></li>
                <li><span >WELCOME</span></li>
                <li><span >WELCOME</span></li>
                <li><span >WELCOME</span></li>
            </ul>
        </div>    
        </div>
       
        <br/>
        <br/>
        <br/>
        <div className="sec2">
        <div data-aos="zoom-in-down" className="imgpi">
            <img src="./images/pic1.png" className="imgpic1" alt="my bio pic"/>
        </div>
        <br/>
        <br/>
        <br/>
        <div data-aos="fade-left" className="sa">
            Samuel <span class="al"> Alegbe</span>
        </div>
        <br/>
        <div data-aos="fade-right" className="hi">
            Hi, I'm samuel, a front-end developer.
        </div>
        </div>

        <br/>
        <br/>
        <br/>
        <br/>
        <div data-aos="zoom-out-up"  className="sec4">
        <div class="wb">Websites</div>
        <br/>
        <a href="https://sam-eight-gamma.vercel.app/"><div data-aos="flip-left" class="un">
            <img src="images/" alt=""/>
            <h4>Portfolio</h4>
        </div>
        </a>
        <br/>
        <a href="tel:+2349014222544"><div data-aos="flip-left" class="un">
            <img src="images/" alt=""/>
            <h4>Hire me</h4>
        </div>
        </a>
        <br/>
      
        <a href="https://www.snapchat.com/add/vibbz_m?share_id=h46NnUE0QF4&locale=en-US"><div data-aos="fiip-up" class="un">
        
            <img src="images/porpic1.png" class="imgpor" alt=""/>
            <h4>Snap</h4>
           
        </div>
        </a> 
        <br/>
        
        <a href="https://paystack.com/pay/m0nsqr118d"><div data-aos="flip-down" class="un">
            <FaCoffee/>
            <h4>Buy me a coffee</h4>
        </div>
        </a>
        </div>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <footer>
        <div class="dd">Designed and developed by Samuel Alegbe.©</div>
        </footer>
        
    </header>
    </>
  )
}

export default Header