

import Soundbar from './subcomponents/Soundbar';
import Bio from "./components/Bio"

import './App.css';
import Socials from './subcomponents/Socials';


function App() {
  return (
    <>
    <Bio/>
    <Socials/>
    <Soundbar/>
    </>
  );
}

export default App;
